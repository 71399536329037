<template>
  <div v-if="list.length > 0" v-loading="loading" style="margin-bottom: 1rem">
    <div class="y-desc" style="font-size: 1rem;margin-bottom: .5rem">待处理申请：被驳回的用户无法再次提交申请直至后台将被驳回记录删除</div>
    <el-card>
      <el-table :data="list">
        <el-table-column prop="id" label="申请编号"></el-table-column>
        <el-table-column prop="uid" label="用户编号"></el-table-column>
        <el-table-column prop="user_nickname" label="用户昵称"></el-table-column>
        <el-table-column prop="user_phone" label="用户手机"></el-table-column>
        <el-table-column prop="apply_award" label="申请时累计佣金"></el-table-column>
        <el-table-column prop="status" :formatter="statusFormat" label="状态"></el-table-column>
        <el-table-column label="申请时间" show-overflow-tooltip>
          <template v-slot="s">{{s.row.created_at | date}}</template>
        </el-table-column>
        <el-table-column label="通过时间" show-overflow-tooltip>
          <template v-slot="s">{{s.row.suc_time | date}}</template>
        </el-table-column>
        <el-table-column label="驳回时间" show-overflow-tooltip>
          <template v-slot="s">{{s.row.fail_time | date}}</template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template v-slot="s">
            <el-button v-if="s.row.status === 1" @click="dose(s.row.id,'suc')">通过</el-button>
            <el-button v-if="s.row.status === 1" @click="dose(s.row.id,'fail')">驳回</el-button>
            <el-button v-if="[1,3].includes(s.row.status)" @click="dose(s.row.id,'del')">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>

    </el-card>
  </div>
</template>

<script>
export default {
  name: "ApplyJudge",
  data(){
    return{
      list: [],
      page: 1,
      total: 0,
      size: 15,
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    dose(id,action=""){
      this.loading = true;
      this.$u.api.shop.plugin.cmo.applyDose({id,action}).then(()=>{
        this.loading = false;
        if (action === "suc")this.$emit("reload");
        this.$message.success("操作成功");
        this.load();
      })
    },
    statusFormat(e){
      let str = "";
      switch (parseInt(e.status)){
        case 1:
          str = "待审";
          break;
        case 2:
          str = "通过";
          break;
        case 3:
          str = "驳回";
          break;
      }
      return str
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.cmo.apply({
        page:this.page,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  }
}
</script>

<style scoped>

</style>